import React, { useState, useEffect } from "react";

import { currentUserVar, useQuery, useReactiveVar, currentCountryVar } from "@apollo";

import { useMediaQuery } from "@hooks";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { navigate } from "@utils";

import { DialogButton, Link, GiftCardsContainer } from "@components";
import { Grid, Typography } from "@material";
import { sendSegmentScreenEvent } from "helpers/analytics";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "80%",
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 16,
    minHeight: 635,
    [theme.breakpoints.down("md")]: {
      width: "90%",
      marginTop: 8,
    },
  },
  headerContainer: {
    marginBottom: 26,
  },
  headerText: {
    color: "black",
    fontWeight: 900,
    fontSize: 42,
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
    },
  },
  offerColumnContainer: {
    display: "grid",
    gridTemplateColumns: "250px auto",
    gridColumnGap: 45,
  },
  filtersColumnContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  filtersColumn: {
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: 78,
      maxWidth: 250,
    },
  },
  noUserOffersContainer: {
    height: 510,
    width: "100%",
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    padding: 24,
    minHeight: 411,
    borderRadius: 4,
    marginBottom: 17,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  cardContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  noUserOffersInnerContainer: {
    width: 450,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      widthPercentageToDP: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  heartIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: 175,
    height: 175,
    backgroundColor: "#FAFAFA",
    marginBottom: 24,
    border: `1px solid #EBEBEB`,
  },
  noUserOffersHeader: { fontSize: 20, fontWeight: 500, marginBottom: 8 },
  noUserOffersSubheader: { fontSize: 18, fontWeight: 400, marginBottom: 12 },
  loadingSpinnerContainer: {
    height: 400,
  },
}));

export default function GiftCardsScreen() {
  const classes: any = useStyles({});
  const small: boolean = useMediaQuery("(max-width:959px)");
  const { t } = useTranslation(["giftCards", "common"]);

  const country = useReactiveVar(currentCountryVar);

  return (
    <>
      <div className={classes.root}>
        <Grid
          item
          container
          xs={12}
          md={6}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item container direction="column" className={classes.headerContainer}>
            <Typography className={classes.headerText}>
              {t("common:giftCards")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={`${!small && classes.offerColumnContainer}`}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sm={12}
            md
            className={classes.cardContainer}
          >
            <>
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={classes.noUserOffersContainer}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.noUserOffersInnerContainer}
                >
                  <Typography className={classes.noUserOffersHeader}>
                    {"<insert content>"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
